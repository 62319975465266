<template>
  <div class="banner">
    <h1>Displaced Bodies</h1>
    <h2>Pop Up Performance by TanzNetzDresden</h2>

    <h3>Open Call for performance</h3>
  </div>

  <div class="content">
    <img alt="" src="@/assets/bg.jpg" class="bg" />
    <img alt="" src="@/assets/bg-bg.png" class="bg-bg" />

    <p class="text1" style="font-weight: lighter;">
      We are looking for four solo-performers who are willing to dance and
      perform for Pop Up Performance
      <span className="bold">Displaced Bodies</span> that will take place on
      Saturday, 12th December in Dresden. Each of those performances will take
      place in different shop- and gallery-windows within Dresden-Neustadt. Each
      performer will create their improvisation based on the concept below. All
      four performers will closely work together with the project team on their
      creation of the performance series.
    </p>

    <p class="team">
      Team: <br />
      Charles A. Washington (Choreography)<br />
      Alba Álvarez (Dance) <br />
      Wiete Sommer (Stage & Costume Design)
    </p>

    <p style="font-weight: lighter;">
      <span className="bold">Displaced Bodies</span> is a response to the
      continued closure of clubs and aims to transfer the value of these places
      and their people to a new environment. Clubbing experience and similar
      gatherings allow the creation of imagination and re-interpretation of the
      relation between spaces and people, also called heterotopia, in which an
      individual's existence is free for self-expression and self-development.
      Essential spaces for those of us who found a type of ‘other’ worldly
      experience, where it was possible to create our character we longed to be,
      a character that the everyday social functionality of living did not
      accept.
    </p>

    <p class="text2" style="font-weight: lighter;">
      With the continuation of the closure of nightclubs and other formats of
      social-cultural places, the human body and mind are slowly imprisoned by
      our society and structures in which we live. The counter-creation of
      safe-spaces are needed. With the general forwarding gentrification on
      social-cultural neighbourhoods and the actual on-going pandemic, the
      nightclubs dying is just a question of time. With the leakage of those
      environments that we encounter no longer, do we need to think about an
      alternative and new concept of those heterotopic spaces? In these days,
      can the more and more virtualized experience of such social phenomena be
      replaced? The performance opens a
      <span className="italic">window</span> to an imaginary world or rather an
      imaginary club, open for the public, letting us remind of our power and
      capability of the imagination of free expression.
    </p>

    <img alt="" src="@/assets/bg2.jpg" class="bg2" />
    <img alt="" src="@/assets/bg-bg2.png" class="bg-bg" />

    <p style="font-weight: lighter; font-style: italic;" class="quote">
      “Place, as a metaphor, can be experienced in different ways, existing or
      created. If created, space can be Foucault’s ‘placeless place’, a utopia.
      A place that exists, however, can be a heterotopic space. A heterotopia is
      what we as individuals interpret it to be: it can be a space for
      reconstituting the self, rewriting the scripts of identity and placing the
      self within a context. [...] a heterotopic space in which to build new
      friendships and establish themselves in a local community, creating and
      weaving their experiences into a tapestry that tells their stories of
      immigration and resettlement.“ <br />
      <span
        style="font-weight: lighter; font-style: normal; font-size: 10px; line-height: 130%; display: block;"
        >(International Education Journal, 2006, 7(4), 446-454. ISSN 1443-1475 ©
        2006 Shannon Research Press., p. 446)</span
      >
    </p>
    <p>
      If you are interested in working with us together, please write to us with
      your name, contact and concept.<br /><br />

      <a href="mailto:close1your1eyes@gmail.com">apply here</a>
    </p>
    <p>
      Requirements:
    </p>
    <ul style="font-weight: lighter;">
      <li>
        concept either written or audio file, including images (max 1 page / 3
        min)
      </li>
      <li>performance duration of 2 hours</li>
      <li>
        must be available and resident in Dresden
      </li>
      <li>
        the concept must fit the idea of
        <span className="bold">Displaced Bodies</span> or similar thoughts of a
        heterotopia
      </li>
    </ul>
    <p>
      Deadline: 26 November 2020, 23:59
    </p>

    <p style="font-weight: lighter;">Photos: Toni Petraschk</p>

    <div class="logos">
      <div class="partner">
        <img alt="" src="@/assets/logo-popup.jpeg" />
      </div>
      <div class="partner">
        <img alt="" src="@/assets/logo-tanznetz.png" />
      </div>
      <div class="partner">
        <span>☗ pinkbeton</span>
      </div>
      <div class="partner">
        <img alt="" src="@/assets/logo-charles.png" />
      </div>
      <div class="partner">
        <img alt="" src="@/assets/logo-hole.png" />
      </div>
      <div class="partner">
        <img alt="" src="@/assets/logo-irrlicht.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {}
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Goldman:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

.logos {
  margin-top: 100px;
  padding-top: 16px;
  border-top: 2px solid rgb(60, 21, 39);

  display: flex;
  flex-wrap: wrap;

  .partner {
    width: 80px;
    height: 80px;
    font-size: 10px;
    // background: rgb(71, 71, 71);

    span {
      padding: 8px;
    }
    margin: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

.banner {
  // background: rgb(214, 161, 214);
  height: 40px;

  padding: 16px 25%;
  padding-bottom: 200px;
  padding-top: 40px;

  @media (max-width: 600px) {
    padding: 16px 15%;
    height: 260px;
  }

  @media (max-width: 400px) {
    padding: 16px;
    height: 300px;
  }
}

.content {
  padding: 16px 25%;
  padding-bottom: 200px;
  padding-top: 40px;

  @media (max-width: 600px) {
    padding: 16px 15%;
  }

  @media (max-width: 400px) {
    padding: 16px;
  }
}

.quote {
  transform: translate(-90px, -170px);
  color: rgb(167, 167, 167);

  @media (max-width: 600px) {
    transform: translate(0px, -170px);
    width: 90%;
  }
}

.team {
  transform: translateX(-50px);

  @media (max-width: 600px) {
    transform: translateX(+15px);
  }
}

.text2 {
  transform: translateX(-150px);
  @media (max-width: 600px) {
    transform: translateX(+40px);
    width: 80%;
  }
}

.text1 {
  transform: translateX(-30px);

  @media (max-width: 600px) {
    transform: translateX(-5px);
  }
}

.bg {
  width: 70%;
  transform: translateX(-100px);
  margin-bottom: 40px;

  @media (max-width: 600px) {
    transform: translateX(+20px);
    width: 90%;
  }
}

.bg-bg {
  opacity: 0.1;
  position: absolute;

  width: 60%;
  transform: translate(-300px, -200px);
  margin-bottom: 40px;

  @media (max-width: 600px) {
    transform: translate(-300px, 200px);
    width: 90%;
  }
}

.bg2 {
  width: 80%;
  transform: translateX(200px);
  margin: 40px 0;

  @media (max-width: 600px) {
    transform: translateX(+40px);
  }
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

a {
  color: rgb(190, 40, 184);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

html,
body {
  margin: 0;
  background: rgb(15, 2, 20);
  color: rgb(255, 255, 255);
}

h1 {
  font-family: "Goldman" !important;
  font-size: 63px;
  line-height: 90%;
  margin-bottom: 10px;
}

h2 {
  font-family: "Goldman" !important;
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 30px;

  @media (max-width: 400px) {
    font-size: 20px;
  }
}

h3 {
  text-decoration: overline;
  font-size: 25px;
}

p {
  margin: 25px 0;
}

p,
ul {
  line-height: 135%;

  font-size: 20px;
  font-weight: 500;

  @media (max-width: 400px) {
    font-size: 17px;
  }
}

ul {
  margin-left: 0px;
  padding-left: 0px;

  li {
    list-style: none;
    margin-bottom: 8px;

    &::before {
      content: "⟿ ";
    }
  }
}

#app {
  font-family: "Quicksand", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
